import * as React from 'react'
// import Header from '../components/Header/Header'
// import Footer from '../components/Footer/Footer'
import { Helmet } from 'react-helmet'
import Maintenance from '../components/layoutComponents/maintenance/Maintenance'

const MaintenancePage = () => {
  return (
    <>
      <Helmet>
        <title>Muddy Stilettos</title>
      </Helmet>

      <Maintenance />
      {/* <Footer /> */}
    </>
  )
}

export default MaintenancePage

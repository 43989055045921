import React from 'react'
import Intro from '../../organisms/intro/Intro'
import Social from '../../Social/Social'
import Logomark from '../../Logomark/Logomark'

const Maintenance = () => {
  return (
    <>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%'
        }}
      >
        <div
          style={{
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: '2rem 4vw',
            backdropFilter: 'blur(10px)',
            borderRadius: '1rem',
            maxWidth: '700px',
            width: '95%',
            boxSizing: 'border-box'
          }}
        >
          <Intro removeHr={true} removeMargin>
            <>
              <div style={{ transform: 'scale(3)' }}>
                <Logomark />
              </div>
              <p style={{ padding: '5px' }}></p>
              <h1 style={{ color: 'rgb(231, 44, 94)', whiteSpace: 'nowrap' }}>
                Muddy Stilettos
              </h1>
              <p></p>
              <h2>We’re Sorry!</h2>
              <p>This page is down for a bit of maintenance right now.</p>
              <p>We promise we won’t keep you waiting too long.</p>

              <div
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '5rem'
                }}
              >
                <Social />
              </div>
            </>
          </Intro>
        </div>
      </div>
    </>
  )
}

export default Maintenance
